import React from "react"
import Layout from "../../../components/layouts"
import { Link } from "gatsby"
import ReactWOW from "react-wow"
import SEO from '../../../components/common/SEO'

export default () => {

  const seo = {
    title: "Workhub | Partners | Free EHS Management Software",
    description:
      "Workhub works with leading industry professionals who give clients assistance with implementation of our EHS Management software, expertise, support and counselling.",
  }

  return (
    <Layout>
       <SEO {...seo}/>
      <section>
        <div className="banner_top">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <ReactWOW animation="fadeInUp" delay="0.3s">
                  <h2 
                  className="f_p f_size_40 l_height60" 
                  // style={{color: 'white'}}
                  >
                    Workhub Partners are the <br />
                    <span className="f_700"> Gold Standard </span> of Compliance
                    Experts
                  </h2>
                </ReactWOW>
              </div>
            </div>
           
          </div>
          
{/* <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 206">
<path fill="white" class="st0" d="M0,62l60,12c60,12,180,36,300,36s240-24,360-16c120,7.7,240,48.2,360,56c120,8,240-16,300-28l60-12v96h-60c-60,0-180,0-300,0s-240,0-360,0s-240,0-360,0s-240,0-300,0H0V62z"/>
</svg> */}

        </div>
      </section>

      <div className="container switch-partners" style={{ display: "flex", justifyContent: 'space-around',  flexWrap: 'wrap', maxWidth: '1300px' }}>

<aside style={{ maxWidth: '550px' }}>
  {/* <ReactWOW animation="fadeInLeft" delay="0.3s"> */}
    {/* <h1 style={{ marginBottom: '1rem' }}>Why partner with Workhub?</h1> */}
    <p className="f_size_18 l_height30">
     <h4 id="h4_media" className="h4_media"> Workhub works with leading industry professionals that provide a wide range of compliance and safety consulting
            expertise, including:</h4></p>

    <ul className="analytices_list" style={{ paddingTop: '0rem' }}>
      <li>Implementation of online compliance systems </li>
      <li>Occupational health and safety management systems<br/>(OHS)(OHSMS) (OHSAS) (OH&S) (HSE) (OSHA) (etc...)</li>
      <li>Safety program content development</li>
      <li>Back-office administration</li>
      <li>Forms and inspections </li>
      <li>COR/SECOR</li>
      <li>System training and education to help deliver Workhub solutions to customers.</li>
    </ul>



    {/* <Link
      to="/resources/partners/find-a-partner"
      className="software_banner_btn btn_submit f_size_15 f_500"
      style={{ marginTop: '2rem' }}>Become a Partner</Link> */}
  {/* </ReactWOW> */}
  
</aside>

<aside className="partner_img_media" style={{ maxWidth: '550px' }}>
  {/* <ReactWOW animation="fadeInRight" delay="0.3s"> */}
    <img className="partner-header-image" alt="Man and woman looking at Workhub Dashboard on a laptop." style={{ width: '100%', margin: 'auto' }} src="/partners/why-partner.png" />
    {/* </ReactWOW> */}

</aside>

</div>

<section className="support_help_area sec_pad">
        <div className="container">
          {/* <ReactWOW animation="fadeInUp">
            <h2 className="f_p f_size_30 l_height50 f_600 t_color text-center mb_60">
              What kind of help do you need today?
            </h2>
          </ReactWOW> */}
          <div className="d-flex">
            <div className="support_item">
              <h4 id="partner-h4">Looking for a Partner?</h4>
              <Link
                to="/resources/partners/find-a-partner/"
                className="software_banner_btn btn_submit f_size_15 f_500"
              >
                Search Now
              </Link>
            </div>
            <div className="support_item">
              <h4 id="partner-h4">
                Want to become a Partner?
              </h4>
              <Link
                to="/resources/partners/become-a-partner/"
                className="software_banner_btn btn_submit f_size_15 f_500"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>

      
      {/* <ReactWOW animation="fadeInUp" delay="0.9s">
                  <div className="support_home_img">
                    <img
                      style={{ maxHeight: "465px", marginTop: '1.5rem', marginTop: '2vh', marginBottom: '3vh' }}
                      src="/banner-img/parnership-header.svg"
                      alt=""
                    />
                  </div>
                </ReactWOW> */}

 {/* What do workhub partners do? */}

 <div className="container partner-container" style={{ paddingBottom: '80px', marginTop: '2vh' }}>
        <h2 style={{ marginBottom: '2.5rem', marginTop: '2rem' }}>How can Workhub partners help?</h2>
        {/* <p style={{ marginBottom: '2.5rem', fontSize: '18px' }}>Mauris fermentum nulla eget ex interdum, et commodo mauris posuere.</p> */}
    <div className="partner-grid">

          <div className="partner-cards"><img src="/partners/assistance.png" alt="wrench"/>
            <h5 style={{ marginTop: '1rem' }}>Implementation Assistance </h5>
            <p>Partners are here to guide your company through setting up our easy-to-use software and tailoring it to your needs.   </p>
            </div>

            <div className="partner-cards"><img src="/partners/lightbulb.png" alt="lightbulb"/>
            <h5 style={{ marginTop: '1rem' }}>Industry Expertise </h5>
            <p>Partners are industry experts and well-versed in safety management. Use their knowledge for advice on best practices and support.   </p>
          </div>


          <div className="partner-cards"><img src="/partners/support.png" alt="person on headset"/>
            <h5 style={{ marginTop: '1rem' }}>Support and Consulting </h5>
            <p>Partners can consult you through anything you are unsure about when it comes to the software or safety management needs specific to your company. </p>
        </div>
          {/* Having problems? Your Partners are here for you.  */}


         
          </div>
      </div>
    </Layout>
  )
}
